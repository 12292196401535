body {
    margin: 0;
    font-family: 'quatro_worldpayregular', '-apple-system', 'BlinkMacSystemFont',
        'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', Arial,
        sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafaf9 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
